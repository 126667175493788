





import {Vue, Component} from "vue-property-decorator";
import ConfessionType from "./Confession";
import ZoomPage from "../../../impView/PageSafety";
import HeadTop from "@/components/ShareComponent/HeadTop.vue"

@Component({name: "Confession",components:{ HeadTop }})
export default class Confession extends ZoomPage implements ConfessionType{
}
